import React from "react";

import { Button } from "reactstrap";
import { isEmpty, sleep, addPhoneLink, getImageType, calculateArraySum } from "../../functions/Utility";

function DeliverySignatureArea(props) {
    const {signatureDataBase64, onOpenSignatureModal } = props;

    return (
        <div className="mt-3 mb-3 p-3 d-flex justify-content-between"
            style={{
                backgroundImage: signatureDataBase64 ? `url(data:image/${getImageType(signatureDataBase64).ext};base64,${signatureDataBase64})` : "none",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                border: "3px dotted lightgray",
                color: "gray",
                height: "200px"
            }}>
            <strong>簽名</strong>
            <Button className="align-self-end" color="info" size="lg" onClick={() => onOpenSignatureModal()}>
                <i className="fas fa-signature" />
                <i className="fas fa-pen-fancy" />
            </Button>
        </div>

    );
}

export default DeliverySignatureArea