import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { addGoodInventoryStockTake, updateGoodInventory, updateGoodsRecords, updateGoodSubType, updateGoodType } from "../functions/Goods";
import { getDateStringFromString, getDateString, sleep, getDateDifference, isDateValid } from "../functions/Utility";
import { Button, Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";

const useStyles = makeStyles({
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    cover: {
        minWidth: 120,
        flex: '0.4 0 auto',
    }
});

function GoodStockTakeCard(props) {
    const classes = useStyles();
    const { goodSingleData, onDetailClick, onUpdateStart, onUpdateEnd, finishStockTakeSingleGood, expandAllCard, goodTypeList, goodSubTypeList, cardIndex } = props;

    //  Inventory edit
    const newInventoryStockTakeInputRef = useRef(null);

    // part the data into parts
    const [inventoryData, setInventoryData] = React.useState({});
    const [inventoryStockTakeData, setInventoryStockTakeData] = React.useState({});
    const [pendingDeliveryQuantityData, setPendingDeliveryQuantityData] = React.useState({});

    // For user input and post to db
    const [newInventoryStockTakeValue, setNewInventoryStockTakeValue] = React.useState(0);
    const [newInventoryStockTakeValueIsEdited, setNewInventoryStockTakeValueIsEdited] = React.useState(false);
    const [newInventoryStockTakeValueIsError, setNewInventoryStockTakeValueIsError] = React.useState(false);

    // Control

    const [isFinishedStockTake, setIsFinishedStockTake] = React.useState(false);
    const [expandThisCard, setExpandThisCard] = React.useState(false);
    const [dropdownGoodTypeOpen, setDropdownGoodTypeOpen] = React.useState(false);
    const [dropdownGoodSubTypeOpen, setDropdownGoodSubTypeOpen] = React.useState(false);


    React.useEffect(() => {
        if (goodSingleData.GoodInventories && Array.isArray(goodSingleData.GoodInventories)) {
            // assumption 
            // there will be only one inventory data in one sourceLocation
            setInventoryData(goodSingleData.GoodInventories[0]);
        } else {
            setInventoryData({});
        }

        if (goodSingleData.GoodInventoryStockTakeMostUpdates && Array.isArray(goodSingleData.GoodInventoryStockTakeMostUpdates)) {
            // assumption 
            // there will be only one inventory stock take data in one sourceLocation
            setInventoryStockTakeData(goodSingleData.GoodInventoryStockTakeMostUpdates[0]);
        } else {
            setInventoryStockTakeData({});
        }

        if (goodSingleData.PendingDeliveryQuantities && Array.isArray(goodSingleData.PendingDeliveryQuantities)) {
            // assumption 
            // there will be only one PendingDeliveryQuantity data in one sourceLocation
            setPendingDeliveryQuantityData(goodSingleData.PendingDeliveryQuantities[0]);
        } else {
            setPendingDeliveryQuantityData({});
        }
        setIsFinishedStockTake(goodSingleData.isFinishedStockTake);

    }, [goodSingleData]);

    React.useEffect(() => {
        // Defualt value for the stockTake input
        setNewInventoryStockTakeValue(inventoryData.inventory);
    }, [inventoryData]);

    React.useEffect(() => {
        setIsFinishedStockTake(goodSingleData.isFinishedStockTake);
    }, [goodSingleData.isFinishedStockTake]);

    React.useEffect(() => {
        setExpandThisCard(expandAllCard);
    }, [expandAllCard]);

    //  General data update function
    const onUpdateInventory = async () => {
        onUpdateStart();

        let pendingDeliveryQuantityToUse = 0;
        if (pendingDeliveryQuantityData && pendingDeliveryQuantityData.pendingDeliveryQuantity) {
            pendingDeliveryQuantityToUse = pendingDeliveryQuantityData.pendingDeliveryQuantity;
        } else {
            pendingDeliveryQuantityToUse = 0;
        }

        let newInventoryToSave = newInventoryStockTakeValue - pendingDeliveryQuantityToUse;
        await addGoodInventoryStockTake(goodSingleData.goodID, "CHORKEEMAT", inventoryData.inventory, newInventoryToSave, newInventoryStockTakeValue);
        await updateGoodInventory(goodSingleData.goodID, "CHORKEEMAT", newInventoryToSave, inventoryData.minInventory, inventoryData.maxInventory);

        finishStockTakeSingleGood(goodSingleData.goodID, "CHORKEEMAT");

        await sleep(200);


        onUpdateEnd();
    };

    const onUpdateGoodType = async (newGoodType) => {
        onUpdateStart();
        await updateGoodType(goodSingleData.goodID, newGoodType);
        await sleep(200);
        goodSingleData.goodType = newGoodType;
        onUpdateEnd();
    };

    const onUpdateGoodSubType = async (newGoodSubType) => {
        onUpdateStart();
        await updateGoodSubType(goodSingleData.goodID, newGoodSubType);
        await sleep(200);
        goodSingleData.goodSubType = newGoodSubType;
        onUpdateEnd();
    };
    return (
        <div className="mt-3">
            {/*overflow: 'visible' is for the dropdown menu to show outside the card */}
            <Card
                className={classes.card}
                style={{ overflow: 'visible', backgroundColor: isFinishedStockTake ? "Aquamarine" : "IndianRed" }}
            >

                <CardActionArea>
                    <Container>
                        <Row>
                            <CardContent className="col-10">

                                <Typography variant="h5" component="h2">
                                   {`${cardIndex}.`}{goodSingleData.goodDescription}
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                    <Dropdown className="mr-2" isOpen={dropdownGoodTypeOpen} toggle={() => { setDropdownGoodTypeOpen(!dropdownGoodTypeOpen) }}>
                                        <DropdownToggle caret color="warning">
                                            {goodSingleData.goodType}
                                        </DropdownToggle>
                                        <DropdownMenu >
                                            {goodTypeList.map((goodTypeItem) => {
                                                //  return <DropdownItem onClick={() => onCarIdDropdownSelect(car.carID)}>{car.carID}</DropdownItem>;
                                                return <DropdownItem onClick={() => { onUpdateGoodType(goodTypeItem.goodType) }}>{goodTypeItem.goodType}</DropdownItem>;
                                            })}
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Dropdown className="mr-2 mt-1" isOpen={dropdownGoodSubTypeOpen} toggle={() => { setDropdownGoodSubTypeOpen(!dropdownGoodSubTypeOpen) }}>
                                        <DropdownToggle caret color="warning">
                                            {goodSingleData.goodSubType}
                                        </DropdownToggle>
                                        <DropdownMenu >
                                            {goodSubTypeList.map((goodSubTypeItem) => {
                                                //  return <DropdownItem onClick={() => onCarIdDropdownSelect(car.carID)}>{car.carID}</DropdownItem>;
                                                return <DropdownItem onClick={() => { onUpdateGoodSubType(goodSubTypeItem.goodSubType) }}>{goodSubTypeItem.goodSubType}</DropdownItem>;
                                            })}
                                        </DropdownMenu>
                                    </Dropdown>
                                </Typography>

                                {expandThisCard &&
                                    <Typography  >
                                        <Typography variant="h6" component="h3">
                                            $ {goodSingleData.goodDefaultUnitPrice} / {goodSingleData.goodDefaultUnitQuantity}{goodSingleData.goodUnit}
                                        </Typography>
                                        <Typography color="textSecondary">
                                            {goodSingleData.goodDefaultWeight} kg / {goodSingleData.goodDefaultUnitQuantity}{goodSingleData.goodUnit}
                                        </Typography>
                                        <Typography className={classes.pos} color="textSecondary">
                                            {goodSingleData.goodRemark}
                                        </Typography>
                                    </Typography>
                                }
                            </CardContent>
                            <CardContent className="col-2 px-0">
                                <Button
                                    className='mx-1 my-1'
                                    color='info'
                                    onClick={() => {
                                        setExpandThisCard(!expandThisCard);
                                    }}
                                >
                                    <i className={`fa fa-caret-${expandThisCard ? "up" : "down"}`} />
                                </Button>

                            </CardContent>
                        </Row>

                    </Container>
                </CardActionArea>

                {expandThisCard &&
                    <CardActions>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>存貨</InputGroupText>
                            </InputGroupAddon>
                            <Input
                                readOnly={true}
                                style={{}}
                                type="text"
                                inputMode="decimal"
                                value={inventoryData.inventory}
                                onChange={(e) => {
                                }} />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>{goodSingleData.goodUnit}</InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </CardActions>

                }

                {expandThisCard &&
                    <CardActions>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>留貨</InputGroupText>
                            </InputGroupAddon>
                            <Input
                                readOnly={true}
                                style={{}}
                                type="text"
                                inputMode="decimal"
                                value={pendingDeliveryQuantityData ? pendingDeliveryQuantityData.pendingDeliveryQuantity : 0}
                                onChange={(e) => {
                                }} />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>{goodSingleData.goodUnit}</InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </CardActions>

                }
                <CardActions>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>點貨</InputGroupText>
                        </InputGroupAddon>
                        <Input ref={newInventoryStockTakeInputRef}
                            style={newInventoryStockTakeValueIsError ? { color: "#c13637", borderColor: "#c13637" } : {}}
                            type="text"
                            inputMode="decimal"
                            value={newInventoryStockTakeValue}
                            onClick={(e) => {
                                // For user experience
                                setNewInventoryStockTakeValue('');
                            }}
                            onChange={(e) => {
                                let inputString = e.target.value.replace(' ', '');
                                if (inputString === "" || isNaN(inputString)) { // isNaN not a number
                                    setNewInventoryStockTakeValueIsError(true);
                                } else {
                                    setNewInventoryStockTakeValueIsError(false);
                                }
                                setNewInventoryStockTakeValueIsEdited(true);
                                setNewInventoryStockTakeValue(inputString); // allow ''
                            }} />
                        {(newInventoryStockTakeInputRef.current !== null && newInventoryStockTakeValueIsEdited === true && newInventoryStockTakeValueIsError === false) ?
                            <InputGroupAddon addonType="append">
                                <Button color="success" onClick={async () => {
                                    await onUpdateInventory();
                                    setNewInventoryStockTakeValueIsEdited(false);
                                }}>
                                    <i className="fas fa-check" />
                                </Button>
                            </InputGroupAddon> : ""
                        }
                        <InputGroupAddon addonType="append">
                            <InputGroupText>{goodSingleData.goodUnit}</InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </CardActions>

                {expandThisCard &&

                    <CardActionArea >
                        <Container>
                            <Row>
                                <CardContent className="col-12">
                                    {(inventoryStockTakeData?.stockTakeDateTime) &&
                                        <Typography className={classes.pos} color="textSecondary">
                                            {getDateString(inventoryStockTakeData?.stockTakeDateTime, true)}
                                        </Typography>
                                    }
                                    {(inventoryStockTakeData?.stockTakeDateTime) &&
                                        <Typography className={classes.pos} color="textSecondary">
                                            {getDateDifference(new Date(inventoryStockTakeData?.stockTakeDateTime), new Date, "hour")}{'小時前'}
                                        </Typography>
                                    }


                                </CardContent>

                            </Row>
                        </Container>
                    </CardActionArea>
                }
            </Card>
        </div>
    );
}

export default GoodStockTakeCard
