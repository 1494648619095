import React from "react";
import { slide as Menu } from 'react-burger-menu'
import { useHistory } from "react-router-dom";
import { getNickname, getRights, logout } from "../../functions/Auth";
import AppPage from "../../AppPage";

import './NavSidebar.default.css';
import './NavSidebar.css';
import { Button, Navbar, NavbarText } from "reactstrap";
import '@fortawesome/fontawesome-free/css/all.min.css';

function NavSidebar() {
    let history = useHistory();

    function onItemClick(route) {
        if (route !== history.location.pathname) {
            history.push(route);
        }
    }

    function onLogoutClick(e) {
        e.preventDefault();
        logout();
        history.push(AppPage.login.route)
    }

    return (
        <>
            <Navbar className="fixed-top justify-content-between" color="primary" light expand="md">
                <Menu className="m-0">
                    <div className={`menu-item p-3 ${AppPage.deliveryRecord.route === history.location.pathname ? "active" : ""}`}
                        onClick={() => onItemClick(AppPage.deliveryRecord.route)}>
                        <i className={AppPage.deliveryRecord.icon} />
                        <span className="ml-2">{AppPage.deliveryRecord.title}</span>
                    </div>
                    {getRights() !== "driver" ? (
                        <div className={`menu-item p-3 ${AppPage.billRecord.route === history.location.pathname ? "active" : ""}`}
                            onClick={() => onItemClick(AppPage.billRecord.route)}>
                            <i className={AppPage.billRecord.icon} />
                            <span className="ml-2">{AppPage.billRecord.title}</span>
                        </div>
                    ) : ""}
                    <div className={`menu-item p-3 ${AppPage.carMaintenance.route === history.location.pathname ? "active" : ""}`}
                        onClick={() => onItemClick(AppPage.carMaintenance.route)}>
                        <i className={AppPage.carMaintenance.icon} />
                        <span className="ml-2">{AppPage.carMaintenance.title}</span>
                    </div>
                    <div className={`menu-item p-3 ${AppPage.goodSearch.route === history.location.pathname ? "active" : ""}`}
                        onClick={() => onItemClick(AppPage.goodSearch.route)}>
                        <i className={AppPage.goodSearch.icon} />
                        <span className="ml-2">{AppPage.goodSearch.title}</span>
                    </div>

                    {getRights() !== "driver" ? (
                        <div className={`menu-item p-3 ${AppPage.goodStockTake.route === history.location.pathname ? "active" : ""}`}
                            onClick={() => onItemClick(AppPage.goodStockTake.route)}>
                            <i className={AppPage.goodStockTake.icon} />
                            <span className="ml-2">{AppPage.goodStockTake.title}</span>
                        </div>
                    ) : ""}
                    <hr className="border-light" />
                    {getRights() !== "driver" ? (

                        <div className={`menu-item p-3 ${history.location.pathname.startsWith(AppPage.wiki.route) ? "active" : ""}`}
                            onClick={() => onItemClick(AppPage.wiki.route)}>
                            <i className={AppPage.wiki.icon} />
                            <span className="ml-2">{AppPage.wiki.title}</span>
                        </div>

                    ) : ""}
                    {getRights() === "boss" ? (
                        <div className={`menu-item p-3 ${history.location.pathname.startsWith(AppPage.salesStat.route) ? "active" : ""}`}
                            onClick={() => onItemClick(AppPage.salesStat.route)}>
                            <i className={AppPage.salesStat.icon} />
                            <span className="ml-2">{AppPage.salesStat.title}</span>
                        </div>

                    ) : ""}
                    {getRights() === "boss" ? (
                        <div className={`menu-item p-3 ${history.location.pathname.startsWith(AppPage.bonusStat.route) ? "active" : ""}`}
                            onClick={() => onItemClick(AppPage.bonusStat.route)}>
                            <i className={AppPage.bonusStat.icon} />
                            <span className="ml-2">{AppPage.bonusStat.title}</span>
                        </div>
                    ) : ""}
                    {getRights() !== "driver" ? (

                        <div className={`menu-item p-3 ${AppPage.invoice80mmPictureGallery.route === history.location.pathname ? "active" : ""}`}
                            onClick={() => onItemClick(AppPage.invoice80mmPictureGallery.route)}>
                            <i className={AppPage.invoice80mmPictureGallery.icon} />
                            <span className="ml-2">{AppPage.invoice80mmPictureGallery.title}</span>
                        </div>
                    ) : ""}
                    <hr className="border-light" />
                    <div className="menu-item p-3" onClick={onLogoutClick}>
                        <i className="fas fa-sign-out-alt" />
                        <span className="ml-2">登出</span>
                    </div>
                    <hr className="border-light" />
                    <div className="menu-item--small p-3 text-white-50">
                        <span className={"align-middle"}>版本：{process.env.REACT_APP_GIT_COMMIT_HASH}</span>
                        <Button color={"secondary"}
                            className="ml-2"
                            onClick={() => window.location.reload()}>
                            更新
                        </Button>
                    </div>
                </Menu>
                <NavbarText className="text-light">
                    <i className={AppPage.getCurrentPage(history).icon} />{' '}
                    {AppPage.getCurrentPage(history).title}
                </NavbarText>
                <NavbarText className={"text-light pl-2 pr-2 bg-" + getRights()}>
                    {getNickname()}
                </NavbarText>
            </Navbar>
            <div className="pb-2" />
        </>
    );
}

export default NavSidebar
