import api from "./Api";

export const filterGoodArrayByString = (goodArray, search) => {

    if (!goodArray && !Array.isArray(goodArray) && Object.values(goodArray).length < 0) {
        return [];
    }

    if (search === '') {
        return goodArray;
    }
    let searchLowerCase = search.toLowerCase();
    return goodArray.filter(data => {
        let goodDescriptionLowerCase = data.goodDescription ? data.goodDescription.toLowerCase() : "";
        let goodDescriptionTrimmed = goodDescriptionLowerCase.replace(/\-/g, "");

        return (
            (data.goodType && data.goodType.toLowerCase().includes(searchLowerCase)) ||
            (data.goodSubType && data.goodSubType.toLowerCase().includes(searchLowerCase)) ||
            (goodDescriptionLowerCase.includes(searchLowerCase)) ||
            (goodDescriptionTrimmed.includes(searchLowerCase)) ||
            (data.goodRemark && data.goodRemark.toLowerCase().includes(searchLowerCase))
        );
    });
};


export const getAllGoodsRecords = async () => {
    const response = await api.get('/goods');
    return response.data;
};
export const getGoodsRecord = async (goodID) => {
    const response = await api.get(`/goods/${goodID}`);
    return response.data;
};

export const updateGoodsRecords = async (goodID, updateData) => {
    const response = await api.put(`/goods/${goodID}`, updateData);
    return response.data;
};


// Below **********************************************
// New API 20241121

export const getAllGoodNew = async (sourceLocation) => {
    const response = await api.get(`/goods/getAllGood/${sourceLocation}`);
    return response.data;
};

export const getSingleGoodNew = async (goodID, sourceLocation) => {
    const response = await api.get(`/goods/getSingleGood/${goodID}/${sourceLocation}`);
    return response.data;
};

export const addGoodInventoryStockTake = async (goodID, sourceLocation, originalInventory, newInventory, stockTakeValue) => {
    let updateData = {
        goodID: goodID,
        sourceLocation: sourceLocation,
        originalInventory: originalInventory,
        newInventory: newInventory,
        stockTakeValue: stockTakeValue
    }
    const response = await api.post(`/goodInventoryStockTake/addStockTake`, updateData);
    return response.data;
};

export const updateGoodInventory = async (goodID, sourceLocation, inventory, minInventory, maxInventory) => {
    let updateData = {
        goodID: goodID,
        sourceLocation: sourceLocation,
        inventory: inventory,
        minInventory: minInventory,
        maxInventory: maxInventory
    }
    const response = await api.put(`/goodInventory/updateInventory`, updateData);
    return response.data;
};

export const updateGoodType = async (goodID, goodType) => {
    let updateData = {
        goodID: goodID,
        goodType: goodType
    }
    const response = await api.put(`/goods/updateGoodType`, updateData);
    return response.data;
};

export const updateGoodSubType = async (goodID, goodSubType) => {
    let updateData = {
        goodID: goodID,
        goodSubType: goodSubType
    }
    const response = await api.put(`/goods/updateGoodSubType`, updateData);
    return response.data;
};