import React, { useRef } from 'react'
import { Redirect, } from "react-router-dom";
import { getRights, isLoggedIn } from "../functions/Auth";
import { Col, Container, Label, Row, Spinner, Button } from "reactstrap";

import NavSidebar from "./components/NavSidebar";
import AppPage from "../AppPage";
import SpinnerOverlay from "./components/SpinnerOverlay";
import ScreenPullToRefresh from "./components/ScreenPullToRefresh";


import {  resizeFile, getImageType } from "../functions/Utility";

import {
    getAllInvoicePromoPicture, getInvoicePromoPictureInUse, getInvoicePromoPictureNotInUse, addInvoicePromoPicture
} from "../functions/Invoice80mmPicture";

import WSPGallery from './components/WSPGallery';


function Invoice80mmPictureGalleryScreen() {

    // Delivery Photo from dB
    const [invoice80mmPicture, setInvoice80mmPicture] = React.useState([]);

    async function getDeliveryPhotoFromBackend() {
        // deliveryPhoto from backend
        let invoice80mmPictureGeneral = [];
        let InvoicePromoPictureInUse = await getInvoicePromoPictureInUse();
        let InvoicePromoPictureNotInUse = await getInvoicePromoPictureNotInUse();
        invoice80mmPictureGeneral = invoice80mmPictureGeneral.concat(InvoicePromoPictureInUse);
        invoice80mmPictureGeneral =   invoice80mmPictureGeneral.concat(InvoicePromoPictureNotInUse);


        setInvoice80mmPicture(invoice80mmPictureGeneral);
    };

    // upload delivery photo
    const inputUploadExistingPhoto = useRef(null);

    const uploadImageTodB = async (imageName, imageToUpload) => {

        //resize file will auto encoded to base 64
        let imageToUploadResized = await resizeFile(imageToUpload);
        let imageBase64 = imageToUploadResized;
        let imageBase64HeaderRemoved = imageBase64.split(',')[1];
        // remove the header from the image
        const testData = await addInvoicePromoPicture(imageName.split('.')[0], imageBase64HeaderRemoved);
        addImageToLocal(imageName.split('.')[0],imageBase64HeaderRemoved )
    };

    const addImageToLocal =  (imageName, imageBase64HeaderRemoved) => {
        const imageDataToAdd = Object.assign({}, {
            ImageID: imageName,
            ImageType: "inUse",
            ImageName: imageName,
            ImageFileType: `image/${getImageType(imageBase64HeaderRemoved).ext}`,
            imageMatchLastUpdateTime: Date.now(),
            imageMatchUserID: '',
            ImageBase64Content: imageBase64HeaderRemoved
        })
        invoice80mmPicture.push(imageDataToAdd);
        setInvoice80mmPicture(invoice80mmPicture);
    };


    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const oneMonthAgo = new Date(today.getTime());
    oneMonthAgo.setDate(today.getDate() - 30);
    const threeMonthAgo = new Date(today.getTime());
    threeMonthAgo.setDate(today.getDate() - 90);
    const halfYearAgo = new Date(today.getTime());
    halfYearAgo.setDate(today.getDate() - 180);

    //  Initial loading
    const [initial, setInitial] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    //  Sorting
    const [sortColumnName, setSortColumnName] = React.useState('paymentDateTime');
    const [sortOrder, setSortOrder] = React.useState(-1);   //  Descending
    //  Item onClick
    const [itemIDClicked, setItemIDClicked] = React.useState('');


    //  Pull down to refresh
    async function onPullDownRefresh() {
        setIsLoading(true);
        await getDeliveryPhotoFromBackend();
        setIsLoading(false);
    }



    //  Run when state value changes
    React.useEffect(() => {
        //  Initial API call
        const initData = async () => {
            //  Load once only
            setInitial(true);
            await getDeliveryPhotoFromBackend();
            setIsLoading(false);
        };
        if (!initial) {
            initData().then();
        }
    }, [initial, invoice80mmPicture]);

    return (isLoggedIn() && getRights() !== 'driver') ? (

        <Container className="pl-3 pr-3">

            {isLoading ? <SpinnerOverlay /> : ""}
            <NavSidebar />
            <ScreenPullToRefresh onRefresh={async () => await onPullDownRefresh()}
                disabled={itemIDClicked !== ''}>

                <Container className="align-self-end" style={{ width: 'fit-content', height: 'fit-content' }}>
                    <input
                        type='file'
                        ref={inputUploadExistingPhoto}
                        style={{
                            display: 'none'
                        }}
                        accept="image/*"
                        onChange={async (e) => {
                            if (e.target.files[0] !== undefined) {
                                // if is undefined, do not do anything
                                setIsLoading(true);
                                await uploadImageTodB(e.target.files[0].name, e.target.files[0]);
                                setIsLoading(false);
                            }
                        }}
                    />
                    <Button className="align-self-end m-2" color="warning" size="lg" onClick={() => inputUploadExistingPhoto.current.click()}>
                        <i className="fa fa-upload" />
                    </Button>
                </Container>
                <WSPGallery URLBase64s={invoice80mmPicture} setIsLoading={setIsLoading} />
               </ScreenPullToRefresh>
        </Container>
    ) : (
        <Redirect to={AppPage.root.route} />
    )
}

export default Invoice80mmPictureGalleryScreen
