import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { getBillStatusColor } from "../functions/Bill";
import { Button } from 'reactstrap';
import { getDateString } from '../functions/Utility';
import { getDeliveryStatusColor } from '../functions/Delivery';

const useStyles = makeStyles({
    card: {
        display: 'flex',
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    cover: {
        minWidth: 120,
        flex: '0.4 0 auto',
    }
});

function BillRecordCard(props) {

    const { onClickCard } = props;
    const classes = useStyles();
    const data = props.data;
    const paymentDate = new Date(data.paymentDateTime);
    const statusColor = getBillStatusColor(data.billStatus);

    // Control
    const [expandThisCard, setExpandThisCard] = React.useState(false);

    return (
        <div className="mt-3">
            <Card className={classes.card}>
                <CardContent className="col-10"
                    onClick={() => { onClickCard() }}
                >
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        <strong className={"bg-" + statusColor + " p-1 text-dark"}>{data.billStatus}</strong>{' '}
                        <strong className={"bg-warning p-1 text-dark"}>{data.paymentMethod}</strong>
                        {/* {data.paymentImageCount > 0 ?
                            <strong className={"pl-2 text-success"}>
                                <i className="fas fa-file-invoice-dollar" />
                            </strong> : ""} */}

                        {data.deliveryRecords && Array.isArray(data.deliveryRecords) &&
                            <strong className={"bg-danger ml-1 p-1 text-dark"}>{`${data.deliveryRecords.length} 送貨`}</strong>
                        }
                        {data.paymentImageCount > 0 &&
                            <strong className={"bg-success ml-1 p-1 text-dark"}>{`${data.paymentImageCount} 入數紙`}</strong>
                        }
                    </Typography>
                    <Typography variant="h5" component="h2">
                        $ {data.netPrice}
                    </Typography>
                    <Typography variant="h5" component="h2">
                        {data.customerName}
                    </Typography>
                    <Typography color="textSecondary">
                        {`${getDateString(paymentDate, true, false)}`}
                    </Typography>
                    <Typography color="textSecondary">
                        {data.goods && Array.isArray(data.goods) && data.goods.length >= 1 &&
                            <span>
                                {`${data.goods.map((item) => {
                                    return `${item.quantity}${item.goodUnit}${item.goodNameShortForm}`
                                }).join(', ')}`}
                            </span>
                        }
                    </Typography>
                    <Typography >
                        {data.deliveryRecords && Array.isArray(data.deliveryRecords) && data.deliveryRecords.length >= 1 &&
                            data.deliveryRecords.map((item, index) => {
                                return <>
                                    <div className='mt-2'>
                                        <strong className={"bg-" + getDeliveryStatusColor(item.deliveryStatus) + " p-1 text-dark"}>{item.deliveryStatus}</strong>
                                        <strong className={"bg-" + getDeliveryStatusColor(item.deliveryStatus) + " p-1 text-dark"}>{item.address}</strong>

                                    </div>
                                    {expandThisCard &&
                                        <div className='mt-2'>
                                            <strong className={"bg-" + getDeliveryStatusColor(item.deliveryStatus) + " p-1 text-dark"}>{item.proposedDateTime}</strong>
                                        </div>
                                    }
                                </>
                            })
                        }
                    </Typography>
                </CardContent>
                <CardContent className="col-2">
                    <Button
                        className=''
                        color='info'
                        onClick={() => {
                            setExpandThisCard(!expandThisCard);
                        }}
                    >
                        <i className={`fa fa-caret-${expandThisCard ? "up" : "down"}`} />
                    </Button>
                </CardContent>
            </Card>
        </div>
    );
}

export default BillRecordCard
