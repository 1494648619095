import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { getRights, getUserById } from "../functions/Auth";
import { getDeliveryPhoto, getDeliverySignature, getDeliveryStatusColor } from "../functions/Delivery";
import { getBillRecordById, getBillStatusColor, isPaid } from "../functions/Bill";
import { calculateArraySum, getDateString, resizeFile, sleep } from "../functions/Utility";
import { Button, CardFooter, CardImg, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import BillSubitemTable from './components/BillSubitemTable';
import DeliverySignatureArea from './components/DeliverySignatureArea';
import DeliveryPhotoArea from './components/DeliveryPhotoArea';
import SpinnerOverlay from "./components/SpinnerOverlay";




const useStyles = makeStyles({
    card: {
        display: 'flex',
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginTop: 16,
    },
    cover: {
        minWidth: 120,
        flex: '0.4 0 auto',
    },
    time: {
        fontSize: 28,
    },
    address: {
        color: 'red'
    },
});


function DeliveryRecordCard(props) {
    const { onOpenDeliverySignatureModal, defaultSignatureImageBase64, defaultSignatureImageBase64MatchingDeliveryID, onUploadDeliveryPhoto, onDeleteDeliveryPhoto, defaultDeliveryPhotoImageBase64, defaultDeliveryPhotoImageBase64MatchingDeliveryID, onOpenBillRecordDetailModal } = props;


    const isDriver = (getRights() === "driver");
    const classes = useStyles();
    const data = props.data;
    const proposedDate = new Date(data.proposedDateTime);
    const driver = data ? getUserById(data.driverID) : { rights: "", nickName: "" };
    const statusColor = getDeliveryStatusColor(data.deliveryStatus);
    const billStatusColor = getBillStatusColor(data.billStatus);
    const deliveryWeight = calculateArraySum(data.goods.map((good) => (good.goodDefaultWeight * good.quantity)));

    // Control
    const [expandThisCard, setExpandThisCard] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    // Signature image for local use
    const [signatureImageBase64, setSignatureImageBase64] = React.useState("");


    // delivery photo image for local use
    const [deliveryPhotoBase64, setDeliveryPhotoBase64] = React.useState("");

    const refreshSignatureDataFromDB = () => {
        getDeliverySignature(data.deliveryID).then((signatureData) => {
            setSignatureImageBase64(signatureData.ImageBase64Content);
        });
    }

    const refreshDeliveryPhotoDataFromDB = () => {
        // deliveryPhoto from backend
        getDeliveryPhoto(data.deliveryID).then((photoData) => {
            setDeliveryPhotoBase64(photoData.ImageBase64Content);
        });
    }

    React.useEffect(() => {
        if (expandThisCard) {
            setIsLoading(true);
            refreshSignatureDataFromDB();
            refreshDeliveryPhotoDataFromDB();
 
           setIsLoading(false);
        }
    }, [expandThisCard]);

    React.useEffect(() => {
        if (!defaultSignatureImageBase64MatchingDeliveryID || defaultSignatureImageBase64MatchingDeliveryID === '') {
            return;
        }
        if (!defaultSignatureImageBase64 || defaultSignatureImageBase64 === '') {
            return;
        }

        if (defaultSignatureImageBase64MatchingDeliveryID === data.deliveryID) {
            // do something
            setSignatureImageBase64(defaultSignatureImageBase64);
        }

    }, [defaultSignatureImageBase64, defaultSignatureImageBase64MatchingDeliveryID]);


    React.useEffect(() => {
        if (!defaultDeliveryPhotoImageBase64MatchingDeliveryID || defaultDeliveryPhotoImageBase64MatchingDeliveryID === '') {
            return;
        }
        if (!defaultDeliveryPhotoImageBase64 || defaultDeliveryPhotoImageBase64 === '') {
            setDeliveryPhotoBase64('');
            return;
        }

        if (defaultDeliveryPhotoImageBase64MatchingDeliveryID === data.deliveryID) {
            // do something
            setDeliveryPhotoBase64(defaultDeliveryPhotoImageBase64);

        }

    }, [defaultDeliveryPhotoImageBase64, defaultDeliveryPhotoImageBase64MatchingDeliveryID]);

    return (
        <div className="mt-3">
            <Card className={classes.card}>

            {isLoading ? <SpinnerOverlay /> : ""}
   
                <CardContent className="col-10"
                    onClick={() => {
                        props.onClickCard(data.deliveryID);
                    }}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        <strong className={"bg-" + statusColor + " p-1 text-dark"}>{data.deliveryStatus}</strong>{' '}
                        <strong className="bg-warning p-1 text-dark">{data.carID}</strong>{' '}
                        <strong className={"bg-" + (driver ? driver.rights : "") + " p-1 text-dark"}>{driver ? driver.nickName : ""}</strong>{' '}
                    </Typography>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        <strong className={"bg-" + billStatusColor + " p-1 text-dark"}>{data.billStatus}</strong>{' '}
                        {isPaid(data.billStatus) === true ? (
                            <>
                                <strong className={"bg-success p-1 text-dark"}>{data.paymentMethod}</strong>{' '}
                            </>
                        ) : ""}
                        {!isDriver &&
                            <strong className={"bg-" + billStatusColor + " p-1 text-dark"}>{`$${data.netPrice}`}{' '}</strong>
                        }


                    </Typography>

                    <Typography variant="h7" component="h7">
                        {getDateString(proposedDate, true, false)}
                    </Typography>
                    <Typography variant="h5" component="h3" >
                        {data.address}
                    </Typography>
                    <Typography variant="h6" component="h4" color="Error">
                        {data.deliveryInternalRemark}
                    </Typography>

                    {data.customerName === "" ? (
                        <>
                            <Typography variant="body2" component="h2" >
                                {data.billRemark}
                            </Typography>
                        </>
                    ) :
                        <>
                            <Typography variant="body2" component="h2" >
                                {data.customerName}
                            </Typography>
                        </>
                    }
                    <Typography color="textSecondary">
                        {data.goodsDescription}
                    </Typography>

                    <Typography color="textSecondary">
                        {Number(deliveryWeight.toFixed(1))}kg{(deliveryWeight >= 1000 ? " （" + Number((deliveryWeight / 1000).toFixed(2)) + "噸）" : "")}
                    </Typography>


                </CardContent>
                <CardContent className="col-2">
                    <Button
                        className=''
                        color='info'
                        onClick={() => {
                            setExpandThisCard(!expandThisCard);
                        }}
                    >
                        <i className={`fa fa-caret-${expandThisCard ? "up" : "down"}`} />
                    </Button>
                </CardContent>



            </Card>

            {expandThisCard &&
                <Card>
                    <CardContent className='col-12'>
                        {/* Below is invoice content */}
                        {!isDriver &&
                            <div onClick={ async () => {
                                let billData = await getBillRecordById(data.paymentID);
                                onOpenBillRecordDetailModal(billData);
                            }}>
                                <Typography className='justify-content-between' variant="h5" component="h3" >
                                    {`總值：$${data.netPrice}`}    
                                </Typography>
                                <Typography className={classes.title + ' mt-1'} color="textSecondary" gutterBottom>
                                    <strong className={"bg-info p-1 text-dark"}>{data.paymentID}</strong>{' '}
                                </Typography>
                                <BillSubitemTable
                                    className="mt-2"
                                    subitemArray={data.billSubitems}
                                    paymentID={data.paymentID}
                                />
                            </div>
                        }
                        <DeliverySignatureArea
                            signatureDataBase64={signatureImageBase64}
                            onOpenSignatureModal={() => { onOpenDeliverySignatureModal() }}
                        />
                        <DeliveryPhotoArea
                            deliveryID={data.deliveryID}
                            deliveryPhotoBase64={deliveryPhotoBase64}
                            onUploadImage={async (fileToUpload) => {
                                // await uploadImage(fileToUpload);
                                onUploadDeliveryPhoto(fileToUpload);
                            }}
                            onDeleteImage={() => {
                                onDeleteDeliveryPhoto();
                            }}
                        />

                    </CardContent>
                </Card>
            }
        </div>
    );
}

export default DeliveryRecordCard
