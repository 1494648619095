import React from "react";

import { Button } from "reactstrap";


function CheckButton(props) {
    const { text, checked, onCheckChange, customColorChecked , customColorUnchecked } = props;

    return (
        <Button
            className="mx-1 my-1"
            color={checked ? (customColorChecked ? customColorChecked : 'success') : (customColorUnchecked ? customColorUnchecked : 'secondary')}
            onClick={() => {onCheckChange(!checked)}}
        >
            {checked &&
                <i className="fas fa-check" />
            }
            <text>{text}</text>
        </Button>

    );
}

export default CheckButton