import React ,  { useRef }from "react";

import { Button, Input, Row, Container } from "reactstrap";
import { isEmpty, sleep, addPhoneLink, getImageType, calculateArraySum } from "../../functions/Utility";
import { saveAs } from "file-saver";

function DeliveryPhotoArea(props) {
    const { deliveryID, deliveryPhotoBase64, onUploadImage, onDeleteImage } = props;

    // upload delivery photo
    const inputUploadExistingPhoto = useRef(null);

    return (
        <div className="mt-3 mb-3 p-3 d-flex justify-content-between"
            // " d-flex justify-content-between"
            style={{
                //   backgroundImage: `url(${imageURLFromDB})`,
                backgroundImage: deliveryPhotoBase64 ? `url(data:image/${getImageType(deliveryPhotoBase64).ext};base64,${deliveryPhotoBase64})` : "none",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                border: "3px dotted lightgray",
                color: "gray",
                height: "200px"
            }}>

            <label for="file-upload-t" className="custom-file-upload"
                style={{
                    width: '100%'
                }}>
            </label>
            <Input
                type='file'
                style={{
                    display: 'none',
                    opacity: '1'
                }}
                //  className="d-flex"
                id="file-upload-t"
                accept="image/*"
                capture="environment"
                onChange={async (e) => {
                    if (e.target.files[0] !== undefined) {
                        // if is undefined, do not do anything
                        onUploadImage(e.target.files[0]);
                    }
                }}
            />
            <Container className="align-self-end" style={{ width: 'fit-content', height: 'fit-content' }}>
                <Row>
                    <input
                        type='file'
                        ref={inputUploadExistingPhoto}
                        style={{
                            display: 'none'
                        }}
                        accept="image/*"
                        onChange={async (e) => {
                            if (e.target.files[0] !== undefined) {
                                // if is undefined, do not do anything
                                onUploadImage(e.target.files[0]);
                            }
                        }}
                    />
                    <Button className="align-self-end m-1" color="warning" size="lg" onClick={() => inputUploadExistingPhoto.current.click()}>
                        <i className="fa fa-upload" />
                    </Button>
                </Row>
                <Row>
                    <Button className="align-self-end m-1" color="info" size="lg" onClick={() => {

                        if (deliveryPhotoBase64 === '' || !deliveryPhotoBase64) {
                            // do not do anything
                        } else {

                            saveAs(`data:image/${getImageType(deliveryPhotoBase64).ext};base64,${deliveryPhotoBase64}`, `${deliveryID}.${getImageType(deliveryPhotoBase64).ext}`);
                        }
                    }}>
                        <i className="fa fa-download" />
                    </Button>
                </Row>
                <Row >
                    <Button className="align-self-end m-1" color="danger" size="lg"
                        onClick={async () => {
                            if (deliveryPhotoBase64 === '' || !deliveryPhotoBase64) {
                                // do not delete
                            } else {
                                onDeleteImage();
                            }
                        }}>
                        <i className="fa fa-window-close" />
                    </Button>

                </Row>

            </Container>
        </div>

    );
}

export default DeliveryPhotoArea