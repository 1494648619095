
//  Function
import {matchPath} from "react-router-dom";

function getCurrentPage(history) {
    for (const entries of Object.entries(AppPage)) {
        const page = entries[1];
        if (typeof page === 'function') continue;
        if (matchPath(history.location.pathname, page.route)) {
            return page;
        }
    }
}

//  Route
const AppPage = {
    getCurrentPage: getCurrentPage,
    login: {
        route: "/app/login",
        title: "登入",
    },
    deliveryRecord: {
        route: "/app/deliveryRecord",
        title: "送貨單記錄",
        icon: "fas fa-truck-moving"
    },
    billRecord: {
        route: "/app/billRecord",
        title: "發票記錄",
        icon: "fas fa-file-invoice-dollar"
    },
    carMaintenance: {
        route: "/app/carMaintenance",
        title: "車輛維修管理",
        icon: "fas fa-wrench"
    },
    goodSearch: {
        route: "/app/goodSearch",
        title: "貨倉找貨",
        icon: "fas fa-warehouse"
    },
    goodStockTake: {
        route: "/app/goodStockTake",
        title: "點貨系統",
        icon: "fas fa-warehouse"
    },
    wiki: {
        routeWithParam: "/app/wiki/:category/:article",
        route: "/app/wiki",
        title: "佐記 WIKI",
        icon: "fab fa-wikipedia-w"
    },
    salesStat: {
        route: "/app/salesStat",
        title: "銷售統計",
        icon: "fas fa-truck-moving"
    },
    bonusStat: {
        route: "/app/bonusStat",
        title: "佣金計算",
        icon: "fas fa-file-invoice-dollar"
    },
    invoice80mmPictureGallery: {
        route: "/app/invoice80mmPictureGallery",
        title: "80mm單圖",
        icon: "fas fa-truck-moving"

    },
    root: {
        route: "/",
        title: "主頁",
        icon: "fas fa-home"
    },
    home: {
        route: "/app",
        title: "主頁",
        icon: "fas fa-home"
    },
};

export default AppPage