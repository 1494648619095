import moment from "moment";
import api from "./Api";
import { getAllUsersObjectCache } from "./Auth";

//  Layout data handling functions
export const filterDeliveryRecords = (deliveryData, search, startDate, endDate, statusList, billStatusList) => {
    let userListObject = getAllUsersObjectCache();

    if (!deliveryData || !Array.isArray(deliveryData) || deliveryData.length <= 0) {
        return [];
    }

    return deliveryData.filter(data => {
        let matched;
        let proposedDate = new Date(data.proposedDateTime).setHours(0, 0, 0, 0);

        matched = (
            //  Date Match
            startDate <= proposedDate && proposedDate <= endDate
        ) && (
                //  Status Match
                (data.deliveryStatus === '排程中' && statusList.scheduled) ||
                (data.deliveryStatus === '準備好' && statusList.prepared) ||
                (data.deliveryStatus === '送緊貨' && statusList.onTheWay) ||
                (data.deliveryStatus === '完成' && statusList.complete)
            ) && (
                // bill  Status Match
                (data.billStatus === '已付' && billStatusList.paid) ||
                (data.billStatus === '未付' && billStatusList.notPaid)
            );
        if (matched === false) return matched;

        //  Search string filter
        let deliveryDriver = userListObject[data.driverID];
        if ((data.address && data.address.includes(search)) ||
            (data.customerName && data.customerName.includes(search)) ||
            (data.carID && data.carID.includes(search)) ||
            (data.deliveryRemark && data.deliveryRemark.includes(search)) ||
            (data.deliveryID && data.deliveryID.includes(search)) ||
            (deliveryDriver && deliveryDriver.name && deliveryDriver.name.includes(search)) ||
            (deliveryDriver && deliveryDriver.nickName && deliveryDriver.nickName.includes(search)) ||
            (deliveryDriver && deliveryDriver.chineseName && deliveryDriver.chineseName.includes(search))
        ) {
            return true;
        }
        for (let i = 0; i < data.goods.length; i++) {
            let good = data.goods[i];
            if (good.goodDescription !== null && good.goodDescription.includes(search)) {
                return true;
            }
        }
        return false;
    });
};

export const sortDeliveryRecords = (deliveryData, columnName, order) => {
    if (columnName === '') return deliveryData;

    if (!deliveryData || !Array.isArray(deliveryData) || deliveryData.length <= 0) {
        return [];
    }

    let sortedData = [...deliveryData]; //  Copy data to another array
    sortedData.sort((a, b) => {
        let compare;
        if (columnName === "proposedDateTime") {
            compare = (Date.parse(a[columnName]) > Date.parse(b[columnName])) ? 1 : -1;
        } else if (columnName === "netPrice") {
            compare = (parseFloat(a[columnName]) > parseFloat(b[columnName])) ? 1 : -1;
        }
        return compare * order; //  order: 1=ascending, -1=descending
    });
    return sortedData;
};

export const getAllDeliveryRecords = async () => {
    const response = await api.get('/deliveryRecords');
    return response.data;
};

export const getDeliveryRecordsFast = async () => {
    const response = await api.get('/deliveryRecords/fast');

    return response.data;
};

export const getAllDeliveryRecordDateRange = async (startDateRange, endDateRange) => {
    let startDateRangeString = moment(startDateRange).format('MM/DD/YYYY');
    let endDateRangeString = moment(endDateRange).format('MM/DD/YYYY');
    if (!startDateRangeString || !endDateRangeString || startDateRangeString === '' || endDateRangeString === '') {
        return [];
    }
    const response = await api.get(`/deliveryRecords?start=${startDateRangeString}&end=${endDateRangeString}`);
    return response.data;
};
export const getDeliveryStatusColor = (status) => {
    if (status === "完成") {
        return 'success';
    } else if (status === "排程中") {
        return 'info';
    } else if (status === "送緊貨") {
        return 'warning';
    } else if (status === "準備好") {
        return 'danger';
    }
    return '';
};
export const splitDeliveryRemark = (remark) => {

    if (!remark) return remark;
    return remark.match(/#([^#]*)#/g) || [remark];
};

//  API related functions
export const changeDeliveryStatus = async (deliveryID, status) => {
    const response = await api.put(`/deliveryRecords/${deliveryID}`, { deliveryStatus: status });
    return response.data;
};
export const getAllDrivers = async () => {
    let response = await api.get('/drivers');
    let userList = response.data;
    localStorage.setItem("driverList", JSON.stringify(userList));
    return userList;
};
export const getAllDriversCache = () => {
    //  Get cached list from local storage
    let driverList = localStorage.getItem("driverList");
    if (driverList !== null) return JSON.parse(driverList);
    return [];
};

export const changeDriverID = async (deliveryID, driverID) => {
    const response = await api.put(`/deliveryRecords/${deliveryID}`, { driverID: driverID });
    return response.data;
};
export const changeDeliveryCarID = async (deliveryID, carID) => {
    const response = await api.put(`/deliveryRecords/${deliveryID}`, { carID: carID });
    return response.data;
};

export const changeDeliveryRemark = async (deliveryID, remarkToChange) => {
    const response = await api.put(`/deliveryRecords/${deliveryID}`, { deliveryRemark: remarkToChange });
    return response.data;
};


export const changeDeliveryProposedDatetime = async (deliveryID, datetimeValue) => {
    let dateVar = new Date(datetimeValue);
    let stringSubmit = dateVar.toLocaleDateString('en-US') + ' ' + dateVar.toLocaleTimeString('en-US') + ' GMT';

    const response = await api.put(`/deliveryRecords/${deliveryID}`, { proposedDateTime: stringSubmit });
    return response.data;
};

export const changeDeliveryLocation = async (deliveryID, position) => {
    const response = await api.put(`/deliveryRecords/${deliveryID}`, {
        address_GPS_latitude: position.latitude,
        address_GPS_longtitude: position.longitude
    });
    return response.data;
};

export const addDeliverySignature = async (deliveryID, imageBase64) => {
    const response = await api.post(`/deliveryRecords/${deliveryID}/signature`, {
        ImageBase64Content: imageBase64,
    });
    return response.data;
};

export const getDeliverySignature = async (deliveryID) => {
    const response = await api.get(`/deliveryRecords/${deliveryID}/signature`);
    return response.data;
};

export const addDeliveryPhoto = async (deliveryID, imageBase64) => {
    const response = await api.post(`/deliveryRecords/${deliveryID}/deliveryPhoto`, {
        ImageBase64Content: imageBase64,
    });
    return response.data;
};

export const getDeliveryPhoto = async (deliveryID) => {
    const response = await api.get(`/deliveryRecords/${deliveryID}/deliveryPhoto`);
    return response.data;
};

export const deleteDeliveryPhoto = async (deliveryID) => {
    const response = await api.delete(`/deliveryRecords/${deliveryID}/deliveryPhoto`);
    return response.data;
};