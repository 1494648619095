import React from 'react'
import { Redirect } from "react-router-dom";
import { Col, Container, Row, Spinner, InputGroupButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, InputGroup, InputGroupAddon } from "reactstrap";
import { sort } from "fast-sort";

import NavSidebar from "./components/NavSidebar";
import { getRights, isLoggedIn } from "../functions/Auth";
import AppPage from "../AppPage";
import { getPersonalBonus } from "../functions/BonusStat";
import BonusStatCard from "./BonusStatCard";

import SearchBar from "./components/SearchBar";
import SpinnerOverlay from "./components/SpinnerOverlay";
import ScreenPullToRefresh from "./components/ScreenPullToRefresh";
import { isEmpty } from "../functions/Utility";
import SearchDateRangeBar from "./components/SearchDateRangeBar";
import SearchSortingBar from "./components/SearchSortingBar";
import { useState } from "react";


function BonusStatScreen() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    //  Initial loading
    const [isLoading, setIsLoading] = React.useState(true);
    //  Data
    const [salesStatData, setSalesStatData] = React.useState([]);
    const [displayData, setDisplayData] = React.useState([]);
    const [singleDailySalesData, setSingleDailySalesData] = React.useState({});


    const [searchStartDate, setSearchStartDate] = React.useState(new Date(today.getFullYear(), today.getMonth() - 1, 1));
    const [searchEndDate, setSearchEndDate] = React.useState(new Date(today.getFullYear(), today.getMonth(), 0));

    //Dropdown to store userID
    const [sortDropdownOpen, setSortDropdownOpen] = useState(false);
    const [targetUserID, setTargetUserID] = useState("");


    //  Sorting
    const [sortColumnName, setSortColumnName] = React.useState('date');
    const [sortOrder, setSortOrder] = React.useState(-1);   //  Descending

    //  Sorting
    const columnsToSort = [
        {
            columnName: "date",
            name: "日期"
        },
        {
            columnName: "salesVolume",
            name: "金額"
        },
        {
            columnName: "deliveryCount",
            name: "送貨"
        }
    ];
    //  userID To Get
    const userIDArray = [
        {
            userID: "yhy001",
            name: "茵"
        },
        {
            userID: "jli001",
            name: "Irene"
        },
        {
            userID: "wlw001",
            name: "Teresa"
        },
        {
            userID: "hxy001",
            name: "Melody"
        }
    ];
    function toggleDropDown() {
        setSortDropdownOpen(!sortDropdownOpen)
    }
    function onDropdownClick(userToChoose) {
        setTargetUserID(userToChoose.userID);
    }
    //  Item click and modal exit toggle
    async function onItemClick(singleDailySalesData) {
        setIsLoading(true);
        setSingleDailySalesData(singleDailySalesData);
        setIsLoading(false);
    }


    async function onModalToggle() {
        setSingleDailySalesData({});
    }

    //  Pull down to refresh
    async function onPullDownRefresh() {
        if (!targetUserID) {
            setIsLoading(false);
            return;
        };
        if (targetUserID === "") {
            setIsLoading(false);
            return;
        };

        let salesStatData;
        salesStatData = await getPersonalBonus(targetUserID, searchStartDate, searchEndDate);
        setSalesStatData(salesStatData);
        setDisplayData(salesStatData);
    }

    //  Run when state value changes
    React.useEffect(() => {
        //  Initial API call
        const getDataFromDB = async () => {
            if (!targetUserID) {
                setIsLoading(false);
                return;
            };
            if (targetUserID === "") {
                setIsLoading(false);
                return;
            };

            let salesStatData;
            salesStatData = await getPersonalBonus(targetUserID, searchStartDate, searchEndDate);
            setIsLoading(false);
            setSalesStatData(salesStatData);
            setDisplayData(salesStatData);
        };
        getDataFromDB();
    }, [searchStartDate, searchEndDate, sortColumnName, sortOrder, targetUserID]);


    return (isLoggedIn() && getRights() === 'boss') ? (
        <Container className="pl-3 pr-3">
            {isLoading ? <SpinnerOverlay /> : ""}

            <NavSidebar />
            <ScreenPullToRefresh onRefresh={async () => await onPullDownRefresh()}
                disabled={!isEmpty(singleDailySalesData)}>
                <SearchDateRangeBar onDateRangeChange={(startDate, endDate) => {
                    setSearchStartDate(startDate);
                    setSearchEndDate(endDate);
                }}
                    defaultStartDate={searchStartDate}
                    defaultEndDate={searchEndDate} />
                <SearchSortingBar columnsToSort={columnsToSort}
                    defaultColumn={columnsToSort[0]}
                    onSortChange={(columnName, order) => {
                        setSortColumnName(columnName);
                        setSortOrder(order);
                    }} />

                <Container className="search-sorting-bar">
                    <Row className="mt-2">

                        <InputGroup>
                            <InputGroupAddon addonType="prepend">

                                <InputGroupButtonDropdown addonType="append" isOpen={sortDropdownOpen} toggle={toggleDropDown}>
                                    <DropdownToggle caret>
                                        {'對象'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {userIDArray.map((userToChoose, index) => {
                                            return (
                                                <DropdownItem onClick={() => onDropdownClick(userToChoose)}
                                                    key={userToChoose.userID}>
                                                    {userToChoose.name}
                                                </DropdownItem>
                                            )
                                        })}
                                    </DropdownMenu>
                                </InputGroupButtonDropdown>


                            </InputGroupAddon>
                        </InputGroup>

                    </Row>

                </Container>

                <Row className="bg-secondary mt-2 p-3">
                    <Col xs={6} className="mb-2">
                        <strong className={"bg-primary p-1 text-light"}>總數</strong>{' '}
                        {displayData ? displayData.length : 0}
                    </Col>
                    <Col xs={6} className="mb-2">
                        <strong className={"bg-primary p-1 text-light"}>對象</strong>{' '}
                        {targetUserID}
                    </Col>
                    <Col xs={3} >
                        <strong className={"bg-info p-1 text-dark"}>總金額</strong>{' '}
                        ${displayData ? displayData.reduce(function (previousValue, currentValue) { return previousValue + currentValue.BonusSchemeDetails[0]?.schemeBonus }, 0) : 0}
                    </Col>
                    <Col xs={3}>
                        <strong className={"bg-warning p-1 text-dark"}>平均</strong>{' '}
                        ${displayData ? Math.round(displayData.reduce(function (previousValue, currentValue) { return previousValue + currentValue.BonusSchemeDetails[0]?.schemeBonus }, 0) / displayData.length) : 0}
                    </Col>
                    <Col xs={3}>
                        <strong className={"bg-success p-1 text-dark"}>日數</strong>{' '}
                        {searchEndDate.getDate() - searchStartDate.getDate() + 1}日
                    </Col>
                    <Col xs={3}>
                        <strong className={"bg-success p-1 text-dark"}>平均每日</strong>{' '}
                        ${displayData ? Math.round(displayData.reduce(function (previousValue, currentValue) { return previousValue + currentValue.BonusSchemeDetails[0]?.schemeBonus }, 0) / (searchEndDate.getDate() - searchStartDate.getDate() + 1)) : 0}
                    </Col>
                </Row>
                <Row>

                    {(Object.values(displayData).length > 0) && displayData.map((singleDailySalesData, index, array) => {
                        return (
                            <Col key={singleDailySalesData.date}
                                data-id={singleDailySalesData.date}
                                xs={12} sm={12} md={6} lg={4}>
                                <BonusStatCard data={singleDailySalesData}
                                    onDetailClick={() => onItemClick(singleDailySalesData)}
                                    onUpdateStart={() => setIsLoading(true)}
                                    onUpdateEnd={() => setIsLoading(false)} />
                            </Col>
                        );
                    })}
                </Row>
            </ScreenPullToRefresh>
        </Container>
    ) : (
        <Redirect to={AppPage.root.route} />
    )
}

export default BonusStatScreen
