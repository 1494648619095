import React from "react";

import {Button, Card, CardImg, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";

function BillSubitemTable(props) {
    const {  subitemArray, paymentID } = props;

    return (
        <Container style={{
            color: "black",
            border: "1px solid rgba(0,0,0,.125)",
            borderRadius: ".25rem",
        }}>
            <Row className="bg-secondary pt-2 pb-2">
                <Col xs={6}>項目內容</Col>
                <Col xs={2} className="p-0">單件</Col>
                <Col xs={2} className="p-0">數目</Col>
                <Col xs={2} className="p-0">價格</Col>
            </Row>
            {subitemArray && Array.isArray(subitemArray) && subitemArray.length >= 1 && subitemArray.map((subitem) => (
                <Row key={`${paymentID}-good-${subitem.goodID}`} className="pt-2 pb-2">
                    <Col xs={6}>
                        <div>{subitem.goodDescription}</div>
                        {subitem.remarks !== "" ? (
                            <div className="pl-2 text-danger">{subitem.remarks}</div>
                        ) : ""}
                    </Col>
                    <Col xs={2} className="p-0">${subitem.unitPrice}</Col>
                    <Col xs={2} className="p-0">{subitem.quantity + " " + subitem.goodUnit}</Col>
                    <Col xs={2} className="p-0">${subitem.subItemPrice}</Col>
                </Row>
            ))}
        </Container>
    );
}

export default BillSubitemTable