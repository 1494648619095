import React from 'react'
import { Redirect } from "react-router-dom";
import {
    Col,
    Container,
    Row,
    Button,
    ButtonGroup,
    Label,
    Input
} from "reactstrap";
import { sort } from "fast-sort";

import NavSidebar from "./components/NavSidebar";
import { getRights, isLoggedIn } from "../functions/Auth";
import AppPage from "../AppPage";
import { filterGoodArrayByString, getAllGoodNew, getAllGoodsRecords, getGoodsRecord, getSingleGoodNew } from "../functions/Goods";
import GoodStockTakeCard from "./GoodStockTakeCard";
import GoodSearchDetailModal from "./GoodSearchDetailModal";
import SearchBar from "./components/SearchBar";
import SpinnerOverlay from "./components/SpinnerOverlay";
import ScreenPullToRefresh from "./components/ScreenPullToRefresh";
import { getDateDifference, isDateValid, isEmpty } from "../functions/Utility";
import CheckButton from './components/CheckButton';



function GoodStockTakeScreen() {
    //  Initial loading
    const [initialized, setInitialized] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    //  Data
    const [goodInitArray, setGoodInitArray] = React.useState([]);
    const [goodDisplayArray, setGoodDisplayArray] = React.useState([]);
    const [goodSingleDataSelected, setGoodSingleDataSelected] = React.useState({});

    //Counting
    const [counterFinished, setCounterFinished] = React.useState(0);
    const [counterNotFinished, setCounterNotFinished] = React.useState(0);

    //  Search and filter
    const [searchString, setSearchString] = React.useState('');
    const initState = {
        notFinished: true,
        finished: true
    };
    const [stockTakeStatusList, setStockTakeStatusList] = React.useState(initState);
    const [stockTakeDateTimeBoundaryInHour, setStockTakeDateTimeBoundaryInHour] = React.useState(0);

    // For filtering the list
    // [{checked: true/false, goodSubType: xxx, goodType: yyyy, counter: zzz}, ...]
    const [goodSubTypeList, setGoodSubTypeList] = React.useState([]);
    // [{checked: true/false, goodType: yyyy, counter: zzz}, ...]
    const [goodTypeList, setGoodTypeList] = React.useState([]);

    // Page control
    const [openAllCard, setOpenAllCard] = React.useState(false);
    const [showGoodTypeButtonGroup, setShowGoodTypeButtonGroup] = React.useState(true);
    const [showGoodSubTypeButtonGroup, setShowGoodSubTypeButtonGroup] = React.useState(false);
    const [showTimeRangeButtonGroup, setShowTimeRangeButtonGroup] = React.useState(true);
    const [showLazyLoadingButtonGroup, setShowLazyLoadingButtonGroup] = React.useState(false);

    const [timeRange2hourBoo, setTimeRange2hourBoo] = React.useState(true); // default 2 houors
    const [timeRange6hourBoo, setTimeRange6hourBoo] = React.useState(false);
    const [timeRange12hourBoo, setTimeRange12hourBoo] = React.useState(false);
    const [timeRange24hourBoo, setTimeRange24hourBoo] = React.useState(false);
    const [timeRange48hourBoo, setTimeRange48hourBoo] = React.useState(false);
    const [timeRange168hourBoo, setTimeRange168hourBoo] = React.useState(false);

    // lazy loading
    const [lazyLoadingRangeIndexRange, setLazyLoadingRangeIndexRange] = React.useState(50);
    const [lazyLoadingRangeIndexStart, setLazyLoadingRangeIndexStart] = React.useState(1);
    const [lazyLoadingRangeIndexEnd, setLazyLoadingRangeIndexEnd] = React.useState(50);
    const [totalItemCount, setTotalItemCount] = React.useState(0);


    React.useEffect(() => {
        //  Initial API call
        const initData = async () => {
            await getInitDataFromDB();
        };
        if (!initialized) {
            initData().then(() => {
                setInitialized(true);
                setIsLoading(false);
            });
        }
    }, [initialized])

    //  Run when state value changes
    React.useEffect(() => {
        if (initialized) {
            if (!goodInitArray || !Array.isArray(goodInitArray) || Object.values(goodInitArray).length <= 0) {
                setGoodDisplayArray([]);
                configCounter([]);
                return;
            }

            //  Filter and sort
            let filteredData = goodInitArray;
            filteredData = filterGoodArrayListByGoodTypeList(goodInitArray, goodTypeList);
            filteredData = filterGoodArrayListByGoodSubTypeList(filteredData, goodSubTypeList);
            if (searchString !== '') {
                filteredData = filterGoodArrayByString(filteredData, searchString);
            }
            configCounter(filteredData);
            filteredData = filterStockTakeStatus(filteredData);

            try {
                sort(filteredData).desc(u => u.goodPriority);
            } catch (error) {

            }
            setTotalItemCount(filteredData.length);
            setGoodDisplayArray(filteredData);
            // Reset the range to the front
            setLazyLoadingRangeIndexStart(1);
            setLazyLoadingRangeIndexEnd(lazyLoadingRangeIndexRange);
        }
    }, [searchString, stockTakeStatusList, goodSubTypeList]);

    React.useEffect(() => {
        // After goodTypeList is set
        // will trigger goodSubTypeList to config
        configGoodTypeListFromGoodArray(goodInitArray);
    }, [goodInitArray]);

    React.useEffect(() => {
        // goodTypeList -> goodSubTypeList
        // goodSubTypeList -> filtering
        configGoodSubTypeListFromGoodArrayAndGoodTypeArray(goodInitArray, goodTypeList);
    }, [goodTypeList]);


    React.useEffect(() => {
        if (timeRange2hourBoo) {
            setStockTakeDateTimeBoundaryInHour(2);
        } else if (timeRange6hourBoo) {
            setStockTakeDateTimeBoundaryInHour(6);

        } else if (timeRange12hourBoo) {
            setStockTakeDateTimeBoundaryInHour(12);

        } else if (timeRange24hourBoo) {
            setStockTakeDateTimeBoundaryInHour(24);

        } else if (timeRange48hourBoo) {
            setStockTakeDateTimeBoundaryInHour(48);

        } else if (timeRange168hourBoo) {
            setStockTakeDateTimeBoundaryInHour(168);
        } else {
            setStockTakeDateTimeBoundaryInHour(2);
        }
        // Trigger get data from DB
        setInitialized(false);
    }, [timeRange2hourBoo, timeRange6hourBoo, timeRange12hourBoo, timeRange24hourBoo, timeRange48hourBoo, timeRange168hourBoo]);

    function turnOffAllTimeRangeButton() {
        setTimeRange2hourBoo(false);
        setTimeRange6hourBoo(false);
        setTimeRange12hourBoo(false);
        setTimeRange24hourBoo(false);
        setTimeRange48hourBoo(false);
        setTimeRange168hourBoo(false);
    }

    async function getInitDataFromDB() {
        let goodArrayFromDB;
        goodArrayFromDB = await getAllGoodNew("CHORKEEMAT");
        //Config the boolean isFinishedStockTake
        if (goodArrayFromDB && Array.isArray(goodArrayFromDB) && Object.values(goodArrayFromDB).length >= 1) {
            goodArrayFromDB = goodArrayFromDB.map(singleItem => {
                let isFinishedStockTake;

                if (!singleItem || Object.values(singleItem).length <= 0) {
                    isFinishedStockTake = false;
                }
                let inventoryStockTakeData = singleItem.GoodInventoryStockTakeMostUpdates[0];

                if (!inventoryStockTakeData) {
                    isFinishedStockTake = false;
                }
                if (!inventoryStockTakeData?.stockTakeDateTime) {
                    isFinishedStockTake = false;
                }
                if (!isDateValid(inventoryStockTakeData?.stockTakeDateTime)) {
                    isFinishedStockTake = false;
                }

                // The time is Valid now
                let dateToCompare = new Date(inventoryStockTakeData?.stockTakeDateTime);
                let hourDifference = getDateDifference(dateToCompare, new Date, 'hour');
                if (hourDifference <= stockTakeDateTimeBoundaryInHour) {
                    // Within the time range
                    isFinishedStockTake = true;
                } else {
                    // Out of the time range
                    isFinishedStockTake = false;
                }

                singleItem.isFinishedStockTake = isFinishedStockTake;

                return singleItem;
            })

            setGoodInitArray(goodArrayFromDB);

        } else {
            setGoodInitArray([]);
        }
    }

    function configGoodTypeListFromGoodArray(goodArray) {
        if (!goodArray || !Array.isArray(goodArray) || Object.values(goodArray).length <= 0) {
            setGoodSubTypeList([]);
            setGoodTypeList([]);
            return;
        }
        try {
            let goodTypeResultArray = goodArray.map(item => {
                return {
                    goodType: item.goodType,
                    checked: true,
                    counter: goodArray.filter(countItem => countItem.goodType === item.goodType).length
                }
            });
            if (!goodTypeResultArray || !Array.isArray(goodTypeResultArray) || Object.values(goodTypeResultArray).length <= 0) {
                setGoodTypeList([]);
                return;
            } else {
                //remove duplicates
                goodTypeResultArray = goodTypeResultArray.filter((item, index) => goodTypeResultArray.findIndex(searchItem => (searchItem.goodType === item.goodType)) === index);
                setGoodTypeList(goodTypeResultArray);
            }
        } catch (error) {
            setGoodTypeList([]);
            return;
        }

    }

    function configGoodSubTypeListFromGoodArrayAndGoodTypeArray(goodArray, goodTypeList) {
        if (!goodArray || !Array.isArray(goodArray) || Object.values(goodArray).length <= 0) {
            setGoodSubTypeList([]);
            return;
        }
        if (!goodTypeList || !Array.isArray(goodTypeList) || Object.values(goodTypeList).length <= 0) {
            setGoodSubTypeList([]);
            return;
        }
        try {
            let goodSubTypeResultArray = goodArray.map(item => {
                return {
                    goodSubType: item.goodSubType,
                    goodType: item.goodType,
                    checked: true,
                    counter: goodArray.filter(countItem => countItem.goodSubType === item.goodSubType).length
                }
            });
            if (!goodSubTypeResultArray || !Array.isArray(goodSubTypeResultArray) || Object.values(goodSubTypeResultArray).length <= 0) {
                setGoodSubTypeList([]);
                return;
            } else {
                //remove duplicates
                goodSubTypeResultArray = goodSubTypeResultArray.filter((item, index) => goodSubTypeResultArray.findIndex(searchItem => (searchItem.goodType === item.goodType && searchItem.goodSubType === item.goodSubType)) === index);
                goodSubTypeResultArray = filterGoodSubTypeListByGoodTypeList(goodSubTypeResultArray, goodTypeList);
                setGoodSubTypeList(goodSubTypeResultArray);
            }

        } catch (error) {
            setGoodSubTypeList([]);
            return;
        }

    }

    // Page Action
    // ************************************************************
    //  Pull down to refresh
    async function onPullDownRefresh() {
        await getInitDataFromDB();
    }

    function configCounter(goodArrayToCount) {
        if (!goodArrayToCount || !Array.isArray(goodArrayToCount) || Object.values(goodArrayToCount).length <= 0) {
            setCounterFinished(0);
            setCounterNotFinished(0);
            return;
        }
        try {
            setCounterFinished(goodArrayToCount.filter(item => item.isFinishedStockTake).length);
            setCounterNotFinished(goodArrayToCount.filter(item => !item.isFinishedStockTake).length);
            return;
        } catch (error) {
            setCounterFinished(0);
            setCounterNotFinished(0);
            return;
        }
    }

    function onUpdateGoodTypeListCheckStatus(goodType, checkStatus) {
        try {
            let newList = [...goodTypeList];
            let itemToChange = newList.find(item => item.goodType === goodType);
            itemToChange.checked = checkStatus;
            setGoodTypeList(newList);
        } catch (error) {
            // do nothing
        }
    }

    function onUpdateGoodSubTypeListCheckStatus(goodSubType, goodType, checkStatus) {
        try {
            let newList = [...goodSubTypeList];
            let itemToChange = newList.find(item => (item.goodSubType === goodSubType && item.goodType === goodType));
            itemToChange.checked = checkStatus;
            setGoodSubTypeList(newList);
        } catch (error) {
            // do nothing
        }
    }

    //  Item click and modal exit toggle
    async function onItemClick(goodID) {
        let goodsDetail;
        setIsLoading(true);
        goodsDetail = await getSingleGoodNew(goodID, "CHORKEEMAT");
        setGoodSingleDataSelected(goodsDetail);
        setIsLoading(false);
    }

    async function onModalToggle() {
        setGoodSingleDataSelected({});
    }

    function onStockTakeStatusClick(itemKey) {
        let statusList = Object.assign({}, stockTakeStatusList);
        statusList[itemKey] = !statusList[itemKey]; //  Flip selected checkbox state
        setStockTakeStatusList(statusList);
    }
    // ************************************************************



    // Filter Function
    // ************************************************************

    const filterGoodSubTypeListByGoodTypeList = (goodSubTypeList, goodTypeList) => {
        let res = goodSubTypeList.filter((item) => { return filterGoodSubTypeItemWithGoodTypeList(item, goodTypeList) });
        return res;
    };

    const filterGoodSubTypeItemWithGoodTypeList = (goodSubTypeItemToFilter, goodTypeList) => {
        try {
            // Cross check with goodArrayList
            return goodTypeList.findIndex((item) => { return ((item.goodType === goodSubTypeItemToFilter.goodType) && item.checked) }) != -1;
        } catch (error) {
            return true;
        }
    };


    const filterGoodArrayListByGoodTypeList = (goodArrayList, goodTypeList) => {
        if (!goodArrayList && !Array.isArray(goodArrayList) && Object.values(goodArrayList).length < 0) {
            return [];
        }
        let res = goodArrayList.filter((item) => { return filterGoodItemWithGoodTypeList(item, goodTypeList) });
        return res;
    };

    const filterGoodItemWithGoodTypeList = (goodItemToFilter, goodTypeList) => {
        try {
            // Cross check with goodArrayList
            return goodTypeList.findIndex((item) => { return ((item.goodType === goodItemToFilter.goodType) && item.checked) }) != -1;
        } catch (error) {
            console.log(error);
            return true;
        }
    };

    const filterGoodArrayListByGoodSubTypeList = (goodArrayList, goodSubTypeList) => {
        let res = goodArrayList.filter((item) => { return filterGoodItemWithGoodSubTypeList(item, goodSubTypeList) });
        return res;
    };

    const filterGoodItemWithGoodSubTypeList = (goodItemToFilter, goodSubTypeList) => {
        try {
            // Cross check with goodArrayList
            return goodSubTypeList.findIndex((item) => { return ((item.goodType === goodItemToFilter.goodType) && (item.goodSubType === goodItemToFilter.goodSubType) && item.checked) }) != -1;
        } catch (error) {
            return true;
        }
    };

    function filterStockTakeStatus(goodArrayToFilter) {
        if (!goodArrayToFilter || !Array.isArray(goodArrayToFilter) || Object.values(goodArrayToFilter).length <= 0) {
            return [];
        }
        return goodArrayToFilter.filter(singleItem => {
            if (singleItem.isFinishedStockTake) {
                // Within the time range
                return stockTakeStatusList.finished;
            } else {
                // Out of the time range
                return stockTakeStatusList.notFinished;
            }
        })
    }
    // ************************************************************


    function finishUpdateSingleGood(goodID, sourceLocation) {
        let newList = goodDisplayArray.map(item => {
            let inventoryData = item.GoodInventories[0];
            if (!inventoryData || !inventoryData?.sourceLocation) {
                return item;
            }
            if (item.goodID === goodID && inventoryData?.sourceLocation === sourceLocation) {
                item.isFinishedStockTake = true;
                return item;
            } else {
                return item;
            }
        });
        setGoodDisplayArray(filterStockTakeStatus(newList));
    }

    return (isLoggedIn() && getRights() !== 'driver') ? (
        <Container className="pl-3 pr-3">
            {isLoading ? <SpinnerOverlay /> : ""}
            <NavSidebar />
            <ScreenPullToRefresh onRefresh={async () => await onPullDownRefresh()}
                disabled={!isEmpty(goodSingleDataSelected)}>
                <SearchBar searchValue={searchString}
                    onSearchChange={(search) => {
                        setSearchString(search)
                    }
                    }

                    placeholder="搜尋（貨名／種類／簡稱／備註）" />

                <Row className="mt-2 ml-1">
                    <Button
                        className='my-1'
                        color='info'
                        onClick={() => {
                            setOpenAllCard(!openAllCard);
                        }}
                    >
                        <i className={`fa fa-caret-${openAllCard ? "up" : "down"}`} />
                    </Button>

                    <CheckButton
                        text={"點貨時間"}
                        checked={showTimeRangeButtonGroup}
                        customColorChecked={"success"}
                        customColorUnchecked={"secondary"}
                        onCheckChange={(newValue) => {
                            setShowTimeRangeButtonGroup(newValue)
                        }}
                    />
                    <CheckButton
                        text={"貨物種類"}
                        checked={showGoodTypeButtonGroup}
                        customColorChecked={"info"}
                        customColorUnchecked={"secondary"}
                        onCheckChange={(newValue) => {
                            setShowGoodTypeButtonGroup(newValue)
                        }}
                    />
                    <CheckButton
                        text={"貨物明細種類"}
                        checked={showGoodSubTypeButtonGroup}
                        customColorChecked={"info"}
                        customColorUnchecked={"secondary"}
                        onCheckChange={(newValue) => {
                            setShowGoodSubTypeButtonGroup(newValue)
                        }}
                    />
                </Row>

                {/* Time Range Control Button */}
                {showTimeRangeButtonGroup &&
                    <Row className="mt-2 ml-1">
                        <CheckButton
                            text={"2小時"}
                            checked={timeRange2hourBoo}
                            customColorChecked={"success"}
                            onCheckChange={(newValue) => {
                                turnOffAllTimeRangeButton();
                                setTimeRange2hourBoo(newValue);
                            }}
                        />
                        <CheckButton
                            text={"6小時"}
                            checked={timeRange6hourBoo}
                            customColorChecked={"success"}
                            onCheckChange={(newValue) => {
                                turnOffAllTimeRangeButton();
                                setTimeRange6hourBoo(newValue);
                            }}
                        />
                        <CheckButton
                            text={"12小時"}
                            checked={timeRange12hourBoo}
                            customColorChecked={"success"}
                            onCheckChange={(newValue) => {
                                turnOffAllTimeRangeButton();
                                setTimeRange12hourBoo(newValue);
                            }}
                        />
                        <CheckButton
                            text={"1日"}
                            checked={timeRange24hourBoo}
                            customColorChecked={"success"}
                            onCheckChange={(newValue) => {
                                turnOffAllTimeRangeButton();
                                setTimeRange24hourBoo(newValue);
                            }}
                        />
                        <CheckButton
                            text={"2日"}
                            checked={timeRange48hourBoo}
                            customColorChecked={"success"}
                            onCheckChange={(newValue) => {
                                turnOffAllTimeRangeButton();
                                setTimeRange48hourBoo(newValue);
                            }}
                        />
                        <CheckButton
                            text={"1星期"}
                            checked={timeRange168hourBoo}
                            customColorChecked={"success"}
                            onCheckChange={(newValue) => {
                                turnOffAllTimeRangeButton();
                                setTimeRange168hourBoo(newValue);
                            }}
                        />

                    </Row>
                }

                {/* Good Type Control Button */}
                {showGoodTypeButtonGroup &&
                    <Row className="mt-2 ml-1">
                        {goodTypeList.map((item, index, array) => {
                            return (
                                <CheckButton
                                    text={item.goodType + '(' + item.counter + ')'}
                                    checked={item.checked}
                                    customColorChecked={"info"}
                                    onCheckChange={(newValue) => {
                                        onUpdateGoodTypeListCheckStatus(item.goodType, newValue)
                                    }}
                                />
                            );
                        })}
                    </Row>
                }

                {/* Good SubType Control Button */}
                {showGoodSubTypeButtonGroup &&
                    <Row className="mt-2 ml-1">
                        {goodSubTypeList.map((item, index, array) => {
                            return (
                                <CheckButton
                                    text={item.goodSubType + '(' + item.counter + ')'}
                                    checked={item.checked}
                                    customColorChecked={"info"}
                                    onCheckChange={(newValue) => {
                                        onUpdateGoodSubTypeListCheckStatus(item.goodSubType, item.goodType, newValue)
                                    }}
                                />
                            );
                        })}
                    </Row>
                }

                <Row className="mt-2">
                    <Col xs={6} className="p-1">
                        <div className="bg-danger p-2 text-dark text-center">
                            <Label check>
                                <Input type="checkbox"
                                    onChange={() => onStockTakeStatusClick('notFinished')}
                                    checked={stockTakeStatusList.notFinished} />{' '}
                                <strong>未點貨</strong>
                                <strong>{`(${counterNotFinished}項)`}</strong>
                            </Label>
                        </div>
                    </Col>
                    <Col xs={6} className="p-1">
                        <div className="bg-success p-2 text-dark text-center">
                            <Label check>
                                <Input type="checkbox"
                                    onChange={() => onStockTakeStatusClick('finished')}
                                    checked={stockTakeStatusList.finished} />{' '}
                                <strong>完成點貨</strong>
                                <strong>{`(${counterFinished}項)`}</strong>
                            </Label>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-2 ml-1">
                    <Button
                        className='my-1'
                        color='warning'
                        onClick={() => {
                            if (lazyLoadingRangeIndexStart <= lazyLoadingRangeIndexRange) {
                                setLazyLoadingRangeIndexStart(1);
                            } else {
                                setLazyLoadingRangeIndexStart(lazyLoadingRangeIndexStart - lazyLoadingRangeIndexRange);
                            }
                            if (lazyLoadingRangeIndexEnd <= lazyLoadingRangeIndexRange) {
                                setLazyLoadingRangeIndexEnd(lazyLoadingRangeIndexRange);
                            } else {
                                setLazyLoadingRangeIndexEnd(lazyLoadingRangeIndexEnd - lazyLoadingRangeIndexRange);
                            }
                        }}
                    >
                        <i className={`fa fa-caret-left`} />
                    </Button>
                    <Button
                        className='ml-1 my-1'
                        color='warning'
                        onClick={() => {
                          
                        }}
                    >
                        {`${lazyLoadingRangeIndexStart} - ${lazyLoadingRangeIndexEnd}`}
                    </Button>
                    <Button
                        className='ml-1 my-1'
                        color='warning'
                        onClick={() => {
                            if (lazyLoadingRangeIndexStart >= totalItemCount) {
                                // Back to the front
                                setLazyLoadingRangeIndexStart(1);
                            } else if (lazyLoadingRangeIndexStart + lazyLoadingRangeIndexRange >= totalItemCount) {
                                setLazyLoadingRangeIndexStart(totalItemCount - lazyLoadingRangeIndexEnd);
                            } else {
                                setLazyLoadingRangeIndexStart(lazyLoadingRangeIndexStart + lazyLoadingRangeIndexRange);
                            }
                            if (lazyLoadingRangeIndexEnd >= totalItemCount) {
                                // Back to the front
                                setLazyLoadingRangeIndexStart(1);
                                setLazyLoadingRangeIndexEnd(lazyLoadingRangeIndexRange);
                            } else if (lazyLoadingRangeIndexEnd + lazyLoadingRangeIndexRange >= totalItemCount) {
                                setLazyLoadingRangeIndexEnd(totalItemCount);
                            } else {
                                setLazyLoadingRangeIndexEnd(lazyLoadingRangeIndexEnd + lazyLoadingRangeIndexRange);
                            }
                        }}
                    >
                        <i className={`fa fa-caret-right`} />
                    </Button>



                </Row>
                <Row >
                    {/* {sort(goodDisplayArray).desc(u => u.goodPriority).map((good, index, array) => {
                        return (
                            <Col key={good.goodID}
                                data-id={good.goodID}
                                xs={12} sm={12} md={6} lg={4}>
                               <GoodStockTakeCard goodSingleData={good}
                                    stockTakeDateTimeBoundaryInHour={stockTakeDateTimeBoundaryInHour}
                                    finishStockTakeSingleGood={(goodID, sourceLocation) => {
                                        finishUpdateSingleGood(goodID, sourceLocation);
                                    }}
                                    onDetailClick={() => onItemClick(good.goodID)}
                                    onUpdateStart={() => setIsLoading(true)}
                                    onUpdateEnd={() => setIsLoading(false)}
                                    expandAllCard={openAllCard}
                                    goodTypeList={goodTypeList}
                                    goodSubTypeList={goodSubTypeList} /> 
                            </Col>
                        );
                    })} */}
                    {goodDisplayArray.map((good, indexBase0, array) => {
                        return (
                            <>
                                {(indexBase0 + 1 >= lazyLoadingRangeIndexStart) && (indexBase0 + 1 <= lazyLoadingRangeIndexEnd) &&
                                    <Col key={good.goodID}
                                        data-id={good.goodID}
                                        xs={12} sm={12} md={6} lg={4}>
                                        <GoodStockTakeCard goodSingleData={good}
                                            stockTakeDateTimeBoundaryInHour={stockTakeDateTimeBoundaryInHour}
                                            finishStockTakeSingleGood={(goodID, sourceLocation) => {
                                                finishUpdateSingleGood(goodID, sourceLocation);
                                            }}
                                            onDetailClick={() => onItemClick(good.goodID)}
                                            onUpdateStart={() => setIsLoading(true)}
                                            onUpdateEnd={() => setIsLoading(false)}
                                            expandAllCard={openAllCard}
                                            goodTypeList={goodTypeList}
                                            goodSubTypeList={goodSubTypeList}
                                            cardIndex={indexBase0 + 1} />
                                    </Col>
                                }
                            </>
                        );
                    })}
                </Row>
            </ScreenPullToRefresh>
            <GoodSearchDetailModal isOpen={!isEmpty(goodSingleDataSelected)}
                toggle={onModalToggle}
                data={goodSingleDataSelected} />
        </Container>
    ) : (
        <Redirect to={AppPage.root.route} />
    )
}

export default GoodStockTakeScreen
