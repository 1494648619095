import React from 'react'
import { Redirect, } from "react-router-dom";
import { getRights, isLoggedIn } from "../functions/Auth";
import { Col, Container, Row, Spinner, Button } from "reactstrap";

import NavSidebar from "./components/NavSidebar";
import SearchBar from "./components/SearchBar";
import AppPage from "../AppPage";
import {
    filterDeliveryRecords,
    getAllDrivers,
    getDeliveryRecordsFast,
    sortDeliveryRecords,
    getAllDeliveryRecordDateRange,
    addDeliverySignature,
    addDeliveryPhoto,
    deleteDeliveryPhoto
} from "../functions/Delivery";
import DeliveryRecordCard from "./DeliveryRecordCard";
import SearchDateRangeBar from "./components/SearchDateRangeBar";
import SearchSortingBar from "./components/SearchSortingBar";
import SpinnerOverlay from "./components/SpinnerOverlay";
import DeliveryRecordDetailModal from "./DeliveryRecordDetailModal";
import DeliveryRecordStatusFilter from "./DeliveryRecordStatusFilter";
import ScreenPullToRefresh from "./components/ScreenPullToRefresh";
import { getAllCars } from "../functions/Car";
import BillRecordStatusFilter from "./BillRecordStatusFilter";
import moment from 'moment';
import { getDateDifference, resizeFile } from '../functions/Utility';
import DeliveryRecordSignatureModal from './DeliveryRecordSignatureModal';
import GeneralConfirmModal from './GeneralConfirmModal';
import BillRecordDetailModal from './BillRecordDetailModal';



function DeliveryRecordScreen() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    //  Initial loading
    const [initial, setInitial] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    //  Data
    const [deliveryData, setDeliveryData] = React.useState([]);
    const [fastDataOnly, setFastDataOnly] = React.useState(false);  //  Not loading summary if only fast data is loaded
    const [displayData, setDisplayData] = React.useState([]);
    //  Search and filter
    const [searchString, setSearchString] = React.useState('');
    const [searchStartDate, setSearchStartDate] = React.useState(today);
    const [searchEndDate, setSearchEndDate] = React.useState(today);
    const [deliveryStatusList, setDeliveryStatusList] = React.useState({ scheduled: true, prepared: true, onTheWay: true, complete: true });
    const [billStatusList, setBillStatusList] = React.useState({ notPaid: true, paid: true });
    //  Sorting
    const [sortColumnName, setSortColumnName] = React.useState('proposedDateTime');
    const [sortOrder, setSortOrder] = React.useState(-1);   //  Descending

    //  Item onClick
    const [deliveryIDSelected, setDeliveryIDSelected] = React.useState('');

    // Data handling
    const dataIntervalInteger = 2; // try default n days when
    const maximumIntervalInteger = 7; // make the range doesnt exceed 7 days
    const [dataStartDate, setDataStartDate] = React.useState(today); // when the screen first load default 1 day
    const [dataEndDate, setDataEndDate] = React.useState(today); // when the screen first load default 1 day

    // Delivry Signature
    const [signatureModalIsOpen, setSignatureModalIsOpen] = React.useState(false);
    const [defaultSignatureImageBase64, setDefaultSignatureImageBase64] = React.useState('');

    // Delivry Detial Modal Signature
    const [deliveryDetailModalIsOpen, setDeliveryDetailModalIsOpen] = React.useState(false);

    //Delivery Photo
    const [deleteDeliveryPhotoModalIsOpen, setDeleteDeliveryPhotoModalIsOpen] = React.useState(false);
    const [defaultDeliveryPhotoImageBase64, setDefaultDeliveryPhotoImageBase64] = React.useState('');


    // Bill record detail modal
    const [billRecordDetailModalIsOpen, setBillRecordDetailModalIsOpen] = React.useState(false);
    const [billTotalSelected, setBillTotalSelected] = React.useState({});

    //  Sorting
    const columnsToSort = [
        {
            columnName: "proposedDateTime",
            name: "日期"
        },
        {
            columnName: "netPrice",
            name: "價錢"
        }
    ];

    async function getDataFromDB() {
        setIsLoading(true);
        //  Get 100 records first
        let deliveryData;

        deliveryData = await getAllDeliveryRecordDateRange(dataStartDate, dataEndDate);
        setDeliveryData(deliveryData);
        setIsLoading(false);
    }

    async function onModalToggle() {
        // setDeliveryIDSelected('');
        setDeliveryDetailModalIsOpen(false);
        await getDataFromDB();
    }
    //  Pull down to refresh
    async function onPullDownRefresh() {
        await getDataFromDB();
    }

    // updload 
    const uploadDeliveryPhotoImage = async (deliveryIDToUse, imageToUpload) => {
        setIsLoading(true);
        //resize file will auto encoded to base 64
        let imageToUploadResized = await resizeFile(imageToUpload);
        let imageBase64 = imageToUploadResized;
        let imageBase64HeaderRemoved = imageBase64.split(',')[1];
        // remove the header from the image
        await addDeliveryPhoto(deliveryIDToUse, imageBase64HeaderRemoved);
        setDefaultDeliveryPhotoImageBase64(imageBase64HeaderRemoved);
        setIsLoading(false);
    };

    // delete delivery photo
    const deleteDeliveryPhotoCmd = async (deliveryID) => {
        setIsLoading(true);
        await deleteDeliveryPhoto(deliveryID);
        setIsLoading(false);
    };


    //  Run when state value changes
    React.useEffect(() => {
        if (!deliveryData || !Array.isArray(deliveryData) || deliveryData.length <= 0) {
            setDisplayData([]);
            return;
        }
        //  Filter and sort
        let filteredData, sortedData;
        filteredData = filterDeliveryRecords(deliveryData, searchString, searchStartDate, searchEndDate, deliveryStatusList, billStatusList);
        sortedData = sortDeliveryRecords(filteredData, sortColumnName, sortOrder);
        setDisplayData(sortedData);
    }, [deliveryData, searchString, deliveryStatusList, searchStartDate, searchEndDate, sortColumnName, sortOrder, billStatusList]);


    React.useEffect(() => {
        //  Initial API call
        const initData = async () => {
            //  Load once only
            setInitial(true);

            await getDataFromDB();

            setFastDataOnly(true);
            await getAllDrivers();
            await getAllCars();
            setFastDataOnly(false);


        };
        if (!initial) {
            initData().then();
        }
    }, [initial, dataStartDate, dataEndDate]);

    React.useEffect(() => {
        if (searchStartDate > searchEndDate) {
            // error case
            return;
        }
        if (dataStartDate > dataEndDate) {
            // error case
            return;
        }

        // a logic to compare calendarDate and dataDate
        if (dataStartDate <= searchStartDate && searchEndDate <= dataEndDate) {
            // save, go on
            // just do not trigger the data fetching from server
            return;
        }
        if (dataStartDate > searchStartDate || searchEndDate > dataEndDate) {
            // extend the data date range 
            // The Screen will handle the logic
            setDataStartDate(moment(searchStartDate).add(dataIntervalInteger * -1, 'days').toDate());
            setDataEndDate(moment(searchEndDate).add(dataIntervalInteger * 1, 'days').toDate());
            setInitial(false);
        }

    }, [searchStartDate, searchEndDate]);



    return isLoggedIn() ? (
        <Container className="pl-3 pr-3">
            {isLoading ? <SpinnerOverlay /> : ""}
            <NavSidebar />
            <ScreenPullToRefresh
                onRefresh={async () => await onPullDownRefresh()}
            // disabled={signatureModalIsOpen}
            >
                <SearchBar searchValue={searchString}
                    onSearchChange={(search) => setSearchString(search)}
                    placeholder="搜尋（POS Number／司機／車牌／地址／客戶名／備註）" />
                <SearchDateRangeBar
                    onDateRangeChange={(startDate, endDate) => {
                        setSearchStartDate(startDate);
                        setSearchEndDate(endDate);
                    }}
                    defaultStartDate={searchStartDate}
                    defaultEndDate={searchEndDate}
                    maxDayIntervalInteger={maximumIntervalInteger} />
                <SearchSortingBar columnsToSort={columnsToSort}
                    defaultColumn={columnsToSort[0]}
                    onSortChange={(columnName, order) => {
                        setSortColumnName(columnName);
                        setSortOrder(order);
                    }} />
                <DeliveryRecordStatusFilter onFilterChange={(statusList) => setDeliveryStatusList(statusList)} />

                {getRights() !== "driver" ? (
                    <BillRecordStatusFilter onFilterChange={(statusList) => setBillStatusList(statusList)} />
                ) : ""}

                <Row className="bg-secondary mt-1 p-3">
                    <Col xs={12} className="mb-2">
                        <strong className={"bg-primary p-1 text-light"}>總數</strong>{' '}
                        {fastDataOnly ? <Spinner size="sm" color="primary" /> : displayData.length}
                    </Col>
                    <Col xs={3}>
                        <strong className={"bg-info p-1 text-dark"}>排程中</strong>{' '}
                        {fastDataOnly ? <Spinner size="sm" color="info" /> : displayData.filter(delivery => delivery.deliveryStatus === "排程中").length}
                    </Col>
                    <Col xs={3}>
                        <strong className={"bg-danger p-1 text-dark"}>準備好</strong>{' '}
                        {fastDataOnly ? <Spinner size="sm" color="primary" /> : displayData.filter(delivery => delivery.deliveryStatus === "準備好").length}
                    </Col>
                    <Col xs={3}>
                        <strong className={"bg-warning p-1 text-dark"}>送緊貨</strong>{' '}
                        {fastDataOnly ? <Spinner size="sm" color="warning" /> : displayData.filter(delivery => delivery.deliveryStatus === "送緊貨").length}
                    </Col>
                    <Col xs={3}>
                        <strong className={"bg-success p-1 text-dark"}>完成</strong>{' '}
                        {fastDataOnly ? <Spinner size="sm" color="success" /> : displayData.filter(delivery => delivery.deliveryStatus === "完成").length}
                    </Col>
                </Row>
                <Row>
                    {displayData.map((delivery, index) => (
                        <Col xs={12} sm={12} md={6} lg={4}
                            data-id={delivery.deliveryID}
                            key={delivery.deliveryID}>
                            <DeliveryRecordCard
                                data={delivery}
                                onClickCard={(deliveryID) => {
                                    setDeliveryIDSelected(deliveryID);
                                    setDeliveryDetailModalIsOpen(true);
                                }}
                                onOpenDeliverySignatureModal={() => {
                                    setDeliveryIDSelected(delivery.deliveryID);
                                    setSignatureModalIsOpen(true);
                                }}
                                defaultSignatureImageBase64={defaultSignatureImageBase64}
                                defaultSignatureImageBase64MatchingDeliveryID={deliveryIDSelected}
                                onUploadDeliveryPhoto={async (fileToUpload) => {
                                    setDeliveryIDSelected(delivery.deliveryID);
                                    await uploadDeliveryPhotoImage(delivery.deliveryID, fileToUpload);
                                }}
                                onDeleteDeliveryPhoto={() => {
                                    setDeliveryIDSelected(delivery.deliveryID);
                                    setDeleteDeliveryPhotoModalIsOpen(true);
                                }}
                                defaultDeliveryPhotoImageBase64={defaultDeliveryPhotoImageBase64}
                                defaultDeliveryPhotoImageBase64MatchingDeliveryID={deliveryIDSelected}
                                onOpenBillRecordDetailModal={(billData) => {
                                    setBillTotalSelected(billData);
                                    setBillRecordDetailModalIsOpen(true);
                                }}
                            />
                        </Col>
                    ))}
                </Row>
            </ScreenPullToRefresh>
            <DeliveryRecordDetailModal isOpen={deliveryDetailModalIsOpen}
                toggle={onModalToggle}
                data={deliveryData.find(delivery => delivery.deliveryID === deliveryIDSelected)} />

            <DeliveryRecordSignatureModal isOpen={signatureModalIsOpen}
                toggle={() => setSignatureModalIsOpen(false)}
                onSubmit={async (dataUrl) => {
                    let imageBase64 = dataUrl.split(',')[1];
                    setIsLoading(true);
                    await addDeliverySignature(deliveryIDSelected, imageBase64);
                    setDefaultSignatureImageBase64(imageBase64);
                    setSignatureModalIsOpen(false);
                    setIsLoading(false);
                }} />

            <GeneralConfirmModal isOpen={deleteDeliveryPhotoModalIsOpen}
                headerText={'確認刪除？'}
                toggle={() => setDeleteDeliveryPhotoModalIsOpen(false)}
                onSubmit={async () => {
                    await deleteDeliveryPhotoCmd(deliveryIDSelected);
                    setDefaultDeliveryPhotoImageBase64('');
                    setDeleteDeliveryPhotoModalIsOpen(false);
                }} />

            <BillRecordDetailModal isOpen={billRecordDetailModalIsOpen}
                toggle={() => {
                    setBillRecordDetailModalIsOpen(false);
                }}
                data={billTotalSelected} />
        </Container>
    ) : (
        <Redirect to={AppPage.login.route} />
    )
}

export default DeliveryRecordScreen
