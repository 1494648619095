import React from "react";

import './NavSidebar.default.css';
import './NavSidebar.css';
import { Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import '@fortawesome/fontawesome-free/css/all.min.css';

import "react-datepicker/dist/react-datepicker.css";
import { isAndroid, isChrome, isEdge, isFirefox, isIOS, isSafari } from "react-device-detect";


function SearchBar(props) {

    // const [searchStringToSubmit, setSearchStringToSubmit] = React.useState('');
    //   const [isInputDoingComposition, setIsInputDoingComposition] = React.useState(false);
    // const [changeFromParentBoo, setChangeFromParentBoo] = React.useState(false);



    function onSearchChange(e) {

            props.onSearchChange(e.target.value);
    
    }

    function onHandleCompostionStart(e) {
        // setIsInputDoingComposition(true);
       // setIsInputDoingComposition(true);
    }
    function onHandleCompostionEnd(e) {

        // setIsInputDoingComposition(false);
        // if (isChrome) {

        //    // onSearchChange(e);
        // }
      //  setIsInputDoingComposition(false);
       //  if (isChrome) {
          //  props.onSearchChangeWithCompostion(e.target.value, false);
      //   }

    }

    return (
        <Container>
            <Row>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText><i className="fas fa-search" /></InputGroupText>
                    </InputGroupAddon>
                    <Input className="pl-2 pr-2"
                        placeholder={props.placeholder ?? "搜尋"}
                        value={props.searchValue}
                        onChange={onSearchChange}
                      //  onCompositionStart={onHandleCompostionStart}
                      //  onCompositionEnd={onHandleCompostionEnd}
                    />
                </InputGroup>
            </Row>
        </Container>
    );
}

export default SearchBar